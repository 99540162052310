// @ngInject
function acpOobaAnalyticsModel(
  ACP_OOBA_GA,
  acpAnalytics,
  $state,
  trackingService
) {
  var def = {};

  function isActivation() {
    return $state.is('public.activate') || $state.is('activate-additional');
  }

  function getGetCodeLabel() {
    return isActivation()
      ? ACP_OOBA_GA.LABELS.ACTIVATION_GET_CODE
      : ACP_OOBA_GA.LABELS.GET_CODE;
  }

  function getVerifyCodeLabel() {
    return isActivation()
      ? ACP_OOBA_GA.LABELS.ACTIVATION_VERIFY_CODE
      : ACP_OOBA_GA.LABELS.VERIFY_CODE;
  }

  def.sendSkipOobaEvent = function() {
    acpAnalytics.sendEvent(
      ACP_OOBA_GA.CATEGORY,
      ACP_OOBA_GA.ACTIONS.CLICK_SKIP_FOR_NOW_LINK,
      getGetCodeLabel()
    );
  };

  def.sendSendCodeEvent = function() {
    acpAnalytics.sendEvent(
      ACP_OOBA_GA.CATEGORY,
      ACP_OOBA_GA.ACTIONS.CLICK_SEND_MY_CODE_BUTTON,
      getGetCodeLabel()
    );
  };

  def.sendShowEditProfileDetailsEvent = function() {
    acpAnalytics.sendEvent(
      ACP_OOBA_GA.CATEGORY,
      ACP_OOBA_GA.ACTIONS.CLICK_EDIT_CONTACT_INFO_LINK,
      getGetCodeLabel()
    );
  };

  def.sendReturnCodeEvent = function() {
    acpAnalytics.sendEvent(
      ACP_OOBA_GA.CATEGORY,
      ACP_OOBA_GA.ACTIONS.CLICK_SUBMIT_BUTTON,
      getVerifyCodeLabel()
    );
  };

  def.sendToggleRememberDeviceEvent = function() {
    acpAnalytics.sendEvent(
      ACP_OOBA_GA.CATEGORY,
      ACP_OOBA_GA.ACTIONS.CLICK_REGISTER_DEVICE_CHECKBOX,
      getVerifyCodeLabel()
    );
  };

  def.sendShowRememberDeviceInfoEvent = function() {
    acpAnalytics.sendEvent(
      ACP_OOBA_GA.CATEGORY,
      ACP_OOBA_GA.ACTIONS.CLICK_INFO_ICON,
      getVerifyCodeLabel()
    );
  };

  def.sendGoToCustomerServiceEvent = function(codeSent) {
    var label = codeSent ? getVerifyCodeLabel() : getGetCodeLabel();

    acpAnalytics.sendEvent(
      ACP_OOBA_GA.CATEGORY,
      ACP_OOBA_GA.ACTIONS.CLICK_CONTACT_CUSTOMER_CARE,
      label
    );
  };

  def.sendSendNewCodeEvent = function() {
    acpAnalytics.sendEvent(
      ACP_OOBA_GA.CATEGORY,
      ACP_OOBA_GA.ACTIONS.CLICK_HAVE_A_NEW_ONE_SENT_LINK,
      getVerifyCodeLabel()
    );
  };

  def.sendGetCodeLandingEvent = function() {
    acpAnalytics.sendEvent(
      ACP_OOBA_GA.CATEGORY,
      ACP_OOBA_GA.ACTIONS.LAND_ON_GET_CODE_SCREEN,
      getGetCodeLabel()
    );
  };

  def.sendVerifyCodeLandingEvent = function() {
    acpAnalytics.sendEvent(
      ACP_OOBA_GA.CATEGORY,
      ACP_OOBA_GA.ACTIONS.LAND_ON_VERIFY_CODE_SCREEN,
      getVerifyCodeLabel()
    );
  };

  def.sendGetCodeLandingPageView = function() {
    if ($state.is('public.activate') || $state.is('activation.additional')) {
      trackingService.sendPageView(
        ACP_OOBA_GA.PAGES.ACTIVATION_GET_CODE.TITLE,
        ACP_OOBA_GA.PAGES.ACTIVATION_GET_CODE.URL
      );
    } else {
      acpAnalytics.sendPageView(
        ACP_OOBA_GA.PAGES.GET_CODE.URL,
        ACP_OOBA_GA.PAGES.GET_CODE.TITLE
      );
    }
  };

  def.sendVerifyCodeLandingPageView = function() {
    if ($state.is('public.activate') || $state.is('activation.additional')) {
      trackingService.sendPageView(
        ACP_OOBA_GA.PAGES.ACTIVATION_VERIFY_CODE.TITLE,
        ACP_OOBA_GA.PAGES.ACTIVATION_VERIFY_CODE.URL
      );
    } else {
      acpAnalytics.sendPageView(
        ACP_OOBA_GA.PAGES.VERIFY_CODE.URL,
        ACP_OOBA_GA.PAGES.VERIFY_CODE.TITLE
      );
    }
  };

  return def;
}

export default acpOobaAnalyticsModel;
