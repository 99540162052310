import oobaDomainContent from './ooba-domain-content.yml';
import acpOobaGa from './acp-ooba-ga';
import acpOobaAnalyticsModel from './acp-ooba-analytics-model';
import acpOobaModel from './acp-ooba-model';
import acpOobaClient from './acp-ooba-client';
import comNetspendActivationDomainTracking from 'components/activation/activation-ui/domain/tracking';
import acpCore from 'core';
import ng from 'angular';

const componentName = 'acp.component.ooba-domain';

const component = ng
  .module(componentName, [acpCore.name, comNetspendActivationDomainTracking])
  .factory('acpOobaClient', acpOobaClient)
  .factory('acpOobaModel', acpOobaModel)
  .factory('acpOobaAnalyticsModel', acpOobaAnalyticsModel)
  .constant('ACP_OOBA_GA', acpOobaGa)
  .run(
    /*@ngInject*/ function (contentSectionCache) {
      contentSectionCache.put('components/ooba-domain', oobaDomainContent);
    }
  );

export default component;

// @bazel-test-dep: components/set-ooba
// @bazel-dep: components/ooba-remember-device-info-dialog-bottom-sheet
