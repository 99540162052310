import acpOobaDevicesClearedToastTemplate from './templates/acp-ooba-devices-cleared-toast.html';
// @ngInject
function acpOobaModel(
  acpOobaClient,
  acpOobaAnalyticsModel,
  $q,
  acpCoreDispatcher
) {
  var OOBA_DELIVERY_TYPE = {
    EMAIL: 2,
    PHONE: 1
  };

  var model = {};

  model.dismiss = function() {
    acpOobaAnalyticsModel.sendSkipOobaEvent();

    return acpOobaClient.skipOoba({}).then(
      function() {
        acpCoreDispatcher.setOoba.success.emit();
      },
      function(err) {
        acpCoreDispatcher.setOoba.failed.emit(err._server_errors);

        return $q.reject({
          serverErrors: err._server_errors,
          fieldErrors: err._field_errors
        });
      }
    );
  };

  model.getDeliveryAddresses = function() {
    return acpOobaClient.getDeliveryAddresses({}).then(function(data) {
      var result = {
        phoneAddresses: [],
        emailAddresses: [],
        canUpdateContactInfo: data.can_update_contact_info,
        availableSkips: data.available_ooba_skips
      };
      data.ooba_addresses.forEach(function(it) {
        var newAddress = {
          mask: it.masked_address,
          deliveryCode: it.address
        };

        if (it.delivery_type === OOBA_DELIVERY_TYPE.EMAIL) {
          result.emailAddresses.push(newAddress);
        } else if (it.delivery_type === OOBA_DELIVERY_TYPE.PHONE) {
          result.phoneAddresses.push(newAddress);
        }
      });
      return result;
    });
  };

  model.sendCode = function(address) {
    acpOobaAnalyticsModel.sendSendCodeEvent();

    return acpOobaClient.sendCode({ delivery_address: address }).then(
      function(data) {
        return data;
      },
      function(err) {
        return $q.reject({
          serverErrors: err._server_errors,
          fieldErrors: err._field_errors
        });
      }
    );
  };

  model.returnCode = function(code, rememberDevice, fingerprint) {
    acpOobaAnalyticsModel.sendReturnCodeEvent();

    var data = {
      code_attempt: code,
      fingerprint: fingerprint
    };

    if (rememberDevice) {
      data.remember_device = rememberDevice;
    }

    return acpOobaClient.returnCode(data).then(
      function(data) {
        if (data.response === true) {
          acpCoreDispatcher.setOoba.success.emit();
          return $q.resolve();
        } else {
          return $q.reject({ serverErrors: ['incorrect_code'] });
        }
      },
      function(err) {
        acpCoreDispatcher.setOoba.failed.emit(err._server_errors);

        return $q.reject({
          serverErrors: err._server_errors,
          fieldErrors: err._field_errors
        });
      }
    );
  };

  model.forgetDevices = function() {
    return acpOobaClient.forgetDevices().then(function() {
      acpCoreDispatcher.toast.show.emit({
        template: acpOobaDevicesClearedToastTemplate
      });
    });
  };

  return model;
}

export default acpOobaModel;
