export default {
  CATEGORY: 'ooba',
  ACTIONS: {
    CLICK_SEND_MY_CODE_BUTTON: 'click send my code button',
    CLICK_EDIT_CONTACT_INFO_LINK: 'click edit contact info link',
    CLICK_SKIP_FOR_NOW_LINK: 'click skip for now link',
    CLICK_CONTACT_CUSTOMER_CARE: 'click contact customer care',
    CLICK_REGISTER_DEVICE_CHECKBOX: 'click register device checkbox',
    CLICK_INFO_ICON: 'click info icon',
    CLICK_SUBMIT_BUTTON: 'click submit button',
    CLICK_HAVE_A_NEW_ONE_SENT_LINK: 'click have a new one sent link',
    LAND_ON_GET_CODE_SCREEN: 'viewed get code',
    LAND_ON_VERIFY_CODE_SCREEN: 'viewed verify code'
  },
  PAGES: {
    GET_CODE: {
      URL: '/ooba-get-code',
      TITLE: 'OOBA Get Code'
    },
    VERIFY_CODE: {
      URL: '/ooba-verify-code',
      TITLE: 'OOBA Verify Code'
    },
    ACTIVATION_GET_CODE: {
      URL: 'ooba-get-code',
      TITLE: 'OOBA get code'
    },
    ACTIVATION_VERIFY_CODE: {
      URL: 'ooba-verify-code',
      TITLE: 'OOBA verify code'
    }
  },
  LABELS: {
    GET_CODE: 'ooba-get-code',
    VERIFY_CODE: 'ooba-verify-code',
    ACTIVATION_GET_CODE: 'activation_ooba_get_code',
    ACTIVATION_VERIFY_CODE: 'activation_ooba_verify_code'
  }
};
