// @ngInject
function acpSetOobaClient(webapiResource) {
  var client = {};

  client.skipOoba = webapiResource({
    method: 'POST',
    path: '/v1/ooba/skipooba'
  });

  client.getDeliveryAddresses = webapiResource({
    method: 'GET',
    path: '/v1/ooba/deliveryaddresses'
  });

  client.sendCode = webapiResource({
    method: 'POST',
    path: '/v1/ooba/delivercode'
  });

  client.returnCode = webapiResource({
    method: 'POST',
    path: '/v1/ooba/answerchallenge'
  });

  client.forgetDevices = webapiResource({
    method: 'POST',
    path: '/v1/ooba/forgetdevices'
  });

  return client;
}

export default acpSetOobaClient;
